


















































import { Component, Prop, Vue } from "vue-property-decorator";
import preguntasModule from "@/store/modules/preguntas-module";
import { preguntas } from "@/shared/dtos/preguntas";
import { MessageService } from "@/shared/services/message-service";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
  },
})
export default class config_encuestas_lista extends Vue {
  @Prop({default:true}) cargar_datos!:boolean;
  public search: string = "";
  public created() {
    if(this.cargar_datos){
      preguntasModule.getpreguntasstoList();
    }
  }

  public get datasource() {
    return preguntasModule.preguntas_list;
  }
 

  public get headers() {
    return [
      {
        text: "ID",
        align: "start",
        value: "id",
      },{
        text: "Pregunta",
        align: "start",
        value: "nombre",
      },{
        text: "Libre",
        value: "modo_libre",
      },{
        text: "Puntua",
        value: "puntua",
      },{
        text: "Dashboard",
        value: "nombre_dashboard",
      },
      { text: "", value: "acciones", align: "end" },
    ];
  }

  /**
   * editar
   */
  public editar_rw(obj: preguntas) {
  this.$router.push({name:RouterNames.preguntasformulario,params:{id:obj.id}});
  }

  /**
   * add_new
   */
  public add_new() {
      this.$router.push({name:RouterNames.preguntasformulario,params:{id:"-1"}});
  }
  /**
   *eliminar
   */
  public eliminar(obj: preguntas) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          preguntasModule.eliminarpreguntas(obj).then(()=>{preguntasModule.getpreguntass()});
        }
      }
    );
  }

  
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
